
body {
    /*font-family: 'SVN-KongaPro', sans-serif;*/

}

.bg-main {
    background: linear-gradient(180deg, #ffe9ed 0%, #ff6482 47.66%, #ffe6eb 100%);
}

body {
    user-select: none;
}

img {
    -webkit-user-drag: none;
    user-drag: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: auto; /* Đảm bảo pointer-events được bật để cho phép click */
}

.zaui-snackbar-top {
    top: 40px !important;
}

.element {
    background: linear-gradient(360deg, #fff056 20.59%, #ffffe4 83.82%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    /*font-family: 'SVN-KongaPro', sans-serif;*/

}

.font-1 {
    font-family: 'SVN-KongaPro', sans-serif;
}

.bg-form-input {
    background: rgb(223 11 11);

}


@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

.shake {
    animation: shake 0.5s; /* duration of the shake */
    animation-iteration-count: infinite; /* repeat the animation infinitely */
}

@font-face {
    font-family: "SVN-KongaPro";
    src: url("../components/SVN-KongaPro/SVN-KongaPro.woff2") format("woff2"),
    url("../components/SVN-KongaPro/SVN-KongaPro.otf") format("woff");
    font-weight: normal;
    font-style: normal;
}
